import { combineReducers } from "redux";
import orderDetails from "./order";
import appActions from "./appActions";
import  wallet  from "./wallet";
import profile from "./profile";
import notification from "./notification";
import Register from "./authentication"

const rootReducer = combineReducers({
  orderDetails,
  appActions,
  wallet,
  profile,
  notification,
  userLogin: Register,
});

export default rootReducer;
