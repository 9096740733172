import React from "react";
import {Row, Col, Button } from "antd";
import Loginbanner from "../component/Loginbanner";
import AuthBasePage from '../component/BasePage'

class Success extends React.Component  {

 redirectUser=()=>{
  this.props.history.push("/user/")
  return window.location.reload();
}

render(){
  return (
    <AuthBasePage>
    <div className="lforms">
    <Row>
      <Loginbanner/>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fmcol containers">
        <img src={`${process.env.REACT_APP_MERCHANT_IMAGE}/footerlogo.png`} alt="logo" className="logoslog" />
        <div className="mdle  h90 tcent transf">
          <div className="w-90">
            <div className="py1">
              <img
                src={`${process.env.REACT_APP_IMAGE}/Sucesss.png`}
                alt="success"
                style={{ borderRadius: "50%" }}
              />
              </div>
              <div className="">
            <h3 style={{ fontSize: "21px", fontWeight: "bolder" }}>
              Yay! Account Created
            </h3>
                <p className="grytxt" >Your account has been created succesfully</p>
                </div>
          </div>
        </div>
        <Button type="primary" htmlType="submit" className="regbtn" onClick={this.redirectUser} block>
        Proceed
      </Button>
      </Col>
    </Row>
  </div>
  </AuthBasePage>
      );
  }
};

export default Success;
