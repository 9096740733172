import React from "react";
import { Col } from "antd";

const Loginbanner = () => {
  return (
    <Col xs={24} sm={12} md={12} lg={12} xl={12} className="lgbg d-sm-none">
      {/* <img src="../img/loginbg.png" alt="bg"/> */}
      <div className="mdle h50 tcent ">
        <div className="txfm w-80">
          <h3>Let's Go for You</h3>
          <p>
          At PLS Express we go the extra mile to ensure your packages are delivered speedily and safely.
          </p>
          <div className="py1 mobile-talks">
            <a href={`https://${process.env.REACT_APP_MERCHANT_NAME}.ng`} className=" hover transf">
              <img src={`${process.env.REACT_APP_IMAGE}/Badge+iOS.png`} alt="" />
            </a>
            <a href={`https://${process.env.REACT_APP_MERCHANT_NAME}.ng`} className=" hover transf">
              <img src={`${process.env.REACT_APP_IMAGE}/Badge+Android.png`} alt="" />
            </a>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default Loginbanner;
