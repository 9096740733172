import { Button } from "antd";
import { Link } from "react-router-dom";

import React from "react";

const Btn = (props) => {
  return (
    <Link className="py1" to={props.details.route}>
      <Button type="primary" htmlType="submit" className="regbtn" block>
        {/* Create Account */}
        {props.details.txt}
      </Button>
    </Link>
  );
};

export default Btn;
