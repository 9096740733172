import React from "react";
import {Row, Col } from "antd";
import Btn from "../component/Btn";
import Loginbanner from "../component/Loginbanner";
import AuthBasePage from '../component/BasePage'

const Instruction = () => {
  const details = {
    txt: "I have checked my mail box",
    route: "/otp",
  };
  return (
    <AuthBasePage>
      <div className="lforms">
    <Row>
      <Loginbanner/>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fmcol containers">
        <img src={`${process.env.REACT_APP_MERCHANT_IMAGE}/footerlogo.png`} alt="logo" className="logoslog" />
        <div className="mdle  h90 tcent">
          <div className="w-90">
            <div className="py1">
              <img src={`${process.env.REACT_APP_IMAGE}/kite.png`} alt="success" />
            </div>
            <h3 style={{ fontSize: "21px", fontWeight: "bolder" }}>
              Password Reset Instruction Sent
            </h3>
            <p className="grytxt">
              We have sent a password recovery instructions to your email
            </p>
          </div>
        </div>
        <Btn details={details} />
      </Col>
    </Row>
 </div>
 </AuthBasePage>
      );
};

export default Instruction;
