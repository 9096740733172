import * as actiontypes from "../actionTypes";

const initialState = {}

 const walletInfo = (state = initialState, action) =>{
    switch (action.type) {
        case actiontypes.FETCH_WALLET_BALANCE:{ 
            return { ...state, walletBalance: action.payload}
        }
        case actiontypes.INITIATE_PAYMENT:{ 
            return { ...state, initiatedPaymentInfo: action.payload}
        }
        case actiontypes.CONFIRM_PAYMENT:{ 
            return { ...state, paymentStatus: action.payload}
        }
        case actiontypes.GET_WALLET_HISTORY:{ 
            return { ...state, walletHistory: action.payload}
        }
        case actiontypes.ORDER_PAYMENT_VIA_WALLET:{ 
            return { ...state, walletOrderPayment: action.payload}
        }
        case actiontypes.TRANSACTION_LOADING:{ 
            return { ...state, transactionLoading: !!action.payload}
        }
        default:
           return state;
    }
}

export default walletInfo