import { Layout } from 'antd';
import React from 'react';
import SideMenu from "../../common/sideMenu";
import DashboardContent from './DashboardRoutes';
// import Basepage from '../../common/basePage';
import "../../styles/dashboard.less";
import {Helmet} from 'react-helmet'



const Index = () => {
    let pathArr = window.location.pathname.split('/')
    const title = pathArr[pathArr.length - 1] || 'Dashboard'
    return (
        <React.Fragment>
            <Helmet>
             <title>{`${title} - ${process.env.REACT_APP_MERCHANT_NAME}`}</title>
            </Helmet>
        <Layout>
        <SideMenu/>
        <DashboardContent/>
        </Layout>
        </React.Fragment>
    );
}

export default Index;
