import * as actiontypes from "../actionTypes";

const initialState = {}
const appActions = (state = initialState, action) => {
    switch (action.type) {
      case actiontypes.HANDLE_EDIT: {
        return { ...state, isEdit: action.payload };
      }
      case actiontypes.ORDER_FORM_STEP: {
        return {
          ...state,
          formStep: action.payload
        };
      }
      case actiontypes.CHECK_PRICE_STATUS: {
        return { ...state, isCheckPrice: action.payload };
      }
      case actiontypes.CLIENT_ORDER_STATUS: {
        return { ...state, createOrderStatus: action.payload };
      }
      default:
        return state;
    }
  };

  export default appActions
  