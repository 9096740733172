import React from 'react'
import {Route, Redirect } from 'react-router-dom';
import { USER_INFO } from '../constants';

function PrivateRoute  ({component: Component, ...rest}) {
    const userInfo = JSON.parse(localStorage.getItem(USER_INFO)) || {}
    const status = JSON.parse(localStorage.getItem("onboardStatus")) 
    const token = userInfo.token || "";
    const onboardStatus = status || false;
    return (
        <Route
      {...rest}
      render={
        ({ location, props }) => (
          (token && onboardStatus)
            ? (
                <Component {...props} />
            ) : (
               <Redirect
                 to={{
                   pathname: '/login',
                   state: { from: location }
                 }}
               />
              )
    )}
    />
  );
}


export default PrivateRoute
