import React, { Component } from "react";
import { Row, Col, Button, Form, Input } from "antd";
import Loginbanner from "../component/Loginbanner";
import { Link } from "react-router-dom";
import Loginfmtxt from "../component/Loginfmtxt";
import { connect } from "react-redux";
import "../../../styles/login.less"
import { user_login } from "../../../common/actions/authentication";
import { authMessageNotification } from "../../../common/utils";
import {Helmet} from 'react-helmet'
import AuthBasePage from '../component/BasePage'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state ={
      loading: false
    }
  }

  onFinish = async (values) => {
    this.setState({loading: true})
    const { user_login } = this.props;
    await user_login(values);
    this.onUserLogin();
  };

  onUserLogin = () => {
    const { canLogin, history } = this.props;
    if (canLogin) {
      this.setState({loading: false})
      if (canLogin.status) {
        console.log(canLogin.status, history);
        return window.location = "/user"
      } else {
        authMessageNotification(canLogin);
      }
    }
  };

  render() {
    const details = {
      heading: "Login into your Account",
      paragraph: "Fill in your valid credentials to continue.",
    };
    return (
      <AuthBasePage page="login">
      <React.Fragment>
        <Helmet>
          <title>Login</title>
        </Helmet>
      <div className="lforms">
        <Row style={{width: '100%'}}>
          <Loginbanner />
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fmcol auth-form">
            <div className="containers t-mdle h100 ">
              <div>
                <img
                  src={`${process.env.REACT_APP_MERCHANT_IMAGE}/footerlogo.png`}
                  alt="logo"
                  className="logoslog"
                />
                <Loginfmtxt Details={details} />
                <Form
                  name="basic"
                  onFinish={this.onFinish}
                  initialValues=" "
                  className="lgfms"
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please input a valid email!",
                      },
                    ]}
                  >
                    <Input className="inp w-90" placeholder="Email Address" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      className="inp w-90"
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Form.Item className="pb1">
                    <Link
                      to="/forgot-password"
                      style={{ textDecoration: "underline" }}
                      className="blktxt fmp"
                    >
                      Forgot Password?
                    </Link>
                  </Form.Item>
                  <Form.Item shouldUpdate>
                    {({ getFieldsValue, getFieldsError }) => {
                      const { email, password } = getFieldsValue();
                      getFieldsError();
                      const formIsComplete = !!password && !!email;
                      return (
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="regbtn regs"
                          loading={this.state.loading}
                          disabled={
                            !formIsComplete ||
                            !!getFieldsError().filter(
                              ({ errors }) => errors.length
                            ).length
                          }
                          // style={{ backgroundColor: color }}
                          block
                        >
                          Login into your Account
                        </Button>
                      );
                    }}
                  </Form.Item>
                </Form>
                <div className="tcent fmpr">
                  <span>Don't have an Account? &nbsp;</span>
                  <Link
                    to="/register"
                    style={{ color: "#6BAF43", textDecoration: "underline" }}
                  >
                    Register for one now
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      </React.Fragment>
      </AuthBasePage>
    );
  }
}
const mapStateToProps = (state) => {
  let { userLogin } = state;
  return {
    error: userLogin.error,
    canLogin: userLogin.canLogin,
  };
};

export default connect(mapStateToProps, { user_login })(Login);
