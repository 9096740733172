import * as actiontypes from "../actionTypes";

const initialState = {}

 const notification = (state = initialState, action) =>{
    switch (action.type) {
        case actiontypes.GET_NOTIFICATION:{ 
            return { ...state, notification: action.payload}
        }
    
        default:
           return state;
    }
}

export default notification