import React, { Component } from "react";
import { Row, Col, Button, Form, Input} from "antd";
import { connect } from "react-redux";
import Loginfmtxt from "../component/Loginfmtxt";
import Loginbanner from "../component/Loginbanner";
import { password_reset } from "../../../common/actions/authentication";
import { authMessageNotification } from "../../../common/utils";
import AuthBasePage from "../component/BasePage";

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }
  
  componentDidUpdate(prevProps){
    if(prevProps.passwordReset !== this.props.passwordReset){
    this.updateUserPassword()
    }
  }
  loader = (loading) =>{
    this.setState({
      loading
    })
  }

  onFinish = (values) => {
    const {otpVerification, password_reset, passwordRequest} = this.props
    this.loader(true)
    let type;
    let val;
    if(passwordRequest.via === "phone"){
       val = {
        phone: otpVerification.phone
      }
      type = "phone"
      // password_reset_phone(req)
    } else{
       val = {
        email: otpVerification.email
      }
      type = "email"
    }
    let req = {
      ...values,
      otp: otpVerification.otp,
      ...val
    }
    password_reset(req, type)

  };

  updateUserPassword = ()=>{
    const {passwordReset} = this.props
    this.loader(false)
    if(passwordReset && passwordReset.status){
      this.props.history.push("/login");
      }
      authMessageNotification({message: passwordReset.message})
      
  }

  render() {
    const details = {
      heading: "Update Password",
      paragraph: "Fill in your details to update your password",
    };

    return (
      <AuthBasePage>
        <div className="lforms">
      <Row>
        <Loginbanner/>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fmcol auth-form">
          <div className="containers t-mdle h100 ">
            <div>
              <img
                src={`${process.env.REACT_APP_MERCHANT_IMAGE}/footerlogo.png`}
                alt="logo"
                className="logoslog"
              />
              <Loginfmtxt Details={details} />
              <Form name="basic" className="lgfms" onFinish={this.onFinish}>
                <Form.Item
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message:
                        "Minimum of seven characters, must contain a number and uppercase",
                      pattern: new RegExp(/^(?=.*[A-Z])[A-Za-z\d](?=.*\d).{7,}$/),
                    },
                  ]}
                >
                  <Input.Password className="inp w-95" placeholder="Password" />
                </Form.Item>

                <Form.Item
                  className="pb1"
                  name="password_confirmation"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="inp w-95"
                    placeholder="confirm Password"
                  />
                </Form.Item>
                <Form.Item className="pt4">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="regbtn"
                    loading={this.state.loading}
                    block
                  >
                    Update Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    </AuthBasePage>
        );
  }
}

const mapStateToProps = ({userLogin}) => {
  return {
    otpVerification: userLogin.otpVerification,
    passwordReset: userLogin.passwordReset,
    passwordRequest: userLogin.requestNewPassword,

  };
}
export default connect(mapStateToProps, {password_reset})(UpdatePassword);
