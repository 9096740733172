import React from 'react';
import Siders from './Sider';
import Media from 'react-media'

const Index = (props) => {


    return (
        <Media queries={{
          large: "(min-width: 801px)"
        }}>
          {matches => (
            <React.Fragment>
              {matches.large && <Siders/>}
            </React.Fragment>
          )}
        </Media>
    );
}

export default Index;
