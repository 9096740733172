import React, { Component } from "react";
import {Col, Button, Form, Input, Row } from "antd";
import { Link } from "react-router-dom";
import Loginfmtxt from "../component/Loginfmtxt";
import Loginbanner from "../component/Loginbanner";
import { connect } from "react-redux";
import {  register_user } from "../../../common/actions/authentication";
import { withRouter } from "react-router";
import "../../../styles/login.less"
import {Helmet} from 'react-helmet'
import AuthBasePage from '../component/BasePage'


class CreateAccount extends Component {
 constructor(props) {
   super(props);
   this.state ={
     error:{
       phone: '',
       email: "",
     },
     loading: false
    
   }
 }
 
 loader = (loading) =>{
  this.setState({
    loading
  })
}
  onFinish = async (values) => {
    this.loader(true)
    const { register_user } = this.props;
    const pageRequest = {
      role_id: "1",
      email: values.email,
      full_name: `${values.firstname} ${values.lastname}`,
      phone: values.mobileNumber,
      password: values.password
    };
    await register_user(pageRequest)
    this.errorCheck();
    
  };

  errorCheck = () => {
    const { error } = this.props
        if(error){
          this.loader(false)
          if (error.status === true) {
            // messageNotification(error)
            this.setState({
              error:{
                phone: error?.phone ? error.phone[0] : "",
                email: error?.email ?error.email[0] : "",
                status: error.status
            }})
            
            } else if(error.status === false){
              this.props.history.push("/verify-account");
            }
        }
   
  } 

  render() {
    const details = {
      heading: "Create an Account",
      paragraph: "Tell us about you",
    };
    const {error} = this.state
    return (
      <AuthBasePage page="register">
      <React.Fragment>
      <Helmet>
      <title>Create Account</title>
    </Helmet>
      <Row>
        <Loginbanner />
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="fmcol auth-form">
          <div className="containers t-mdle h100 ">
            <div>
              <img
                src={`${process.env.REACT_APP_MERCHANT_IMAGE}/footerlogo.png`}
                alt="logo"
                className="logoslog"
              />
              <Loginfmtxt Details={details} />
              <Form name="basic" className="lgfms" onFinish={this.onFinish}>
                <Row gutter="24">
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="spaces"
                  >
                    <Form.Item
                      name="firstname"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please input your First Name!",
                        },
                      ]}
                    >
                      <Input className="inp w-90" placeholder="First Name" />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="spaces"
                  >
                    <Form.Item
                      name="lastname"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please input a your Last Name",
                        },
                      ]}
                    >
                      <Input className="inp w-90" placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter="24">
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="spaces"
                  >
                    <Form.Item
                      name="email"
                      hasFeedback
                      help={<small className="dangerText">{error.email}</small>}
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please input a valid email",
                        },
                      ]}
                    >
                      <Input className="inp w-90" placeholder="Email Address" />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="spaces"
                  >
                    <Form.Item
                      name="mobileNumber"
                      hasFeedback
                      help={<small className="dangerText">{error.phone}</small>}
                      rules={[
                        {
                          required: true,
                          message:
                            "Please enter your Mobile Number eg(09099383773)",
                          pattern: new RegExp(/^0\d{10}$/)
                        },
                      ]}
                    >
                      <Input className="inp w-90" placeholder="Mobile Number" />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message:
                        "Minimum of seven characters, must contain a number and uppercase",
                      pattern: new RegExp(/^(?=.*[A-Z])[A-Za-z\d](?=.*\d).{7,}$/),
                    },
                  ]}
                >
                  <Input.Password className="inp w-95" placeholder="Password" />
                </Form.Item>
                <Form.Item className="pb1">
                  <Link
                    to="/forgot-password"
                    style={{ textDecoration: "underline" }}
                    className="blktxt fmp"
                  >
                    Forgot Password?
                  </Link>
                </Form.Item>
                <Form.Item shouldUpdate>
                  {({ getFieldsValue, getFieldsError }) => {
                    const {
                      firstname,
                      lastname,
                      password,
                      email,
                      mobileNumber,
                    } = getFieldsValue();
                    getFieldsError();
                    const formIsComplete =
                      !!firstname &&
                      !!lastname &&
                      !!password &&
                      !!email &&
                      !!mobileNumber;
                    return (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="regbtn"
                        loading={this.state.loading}
                        disabled={
                          !formIsComplete ||
                          !!getFieldsError().filter(
                            ({ errors }) => errors.length
                          ).length
                        }
                        block
                      >
                        Create Account
                      </Button>
                    );
                  }}
                </Form.Item>
              </Form>
              <div className="tcent fmpr">
                <span>Already have an account? &nbsp;</span>
                <Link
                  to="/login"
                  style={{ color: "#6BAF43", textDecoration: "underline" }}
                >
                  Login into your account
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      </React.Fragment>
      </AuthBasePage>
    );
  }
}
const mapStateToProps = (state) => {
  let { userLogin } = state;
  return {
    error: userLogin.error
  }
}
export default connect(mapStateToProps, {register_user}) (withRouter(CreateAccount));
