import React from "react";
import { Layout, Menu, Spin} from "antd";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../actions/authentication"
import { get_profile } from "../actions/profile";
import {truncateString} from '../utils'

const { Sider } = Layout;
class Siders extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      profileImg: '',
      full_name: '',
    }
  }

  componentDidMount(){
    this.props.get_profile()
  }
  componentDidUpdate(prevProps){
    if(prevProps.profile !== this.props.profile){
     this.setProfile()
    }
  }
  setProfile = ()=>{
    const {user} = this.props.profile
    if(user){
      this.setState({
        profileImg: user.profile_image_url,
        full_name: user.full_name,
      })
    }
  }
logoutUser = () =>{
  this.props.logout()
}
  render(){
  const {full_name, profileImg} = this.state
  const {profile} = this.props
  return (
     <Sider
      breakpoint="md"
      collapsedWidth="0"
      className="borddash">
      <div className="side-menu">
        <div className="logo borddashb w-80">
          <a className="logoslogs mdle pb1" href={`https://${process.env.REACT_APP_MERCHANT_NAME}.ng`}><img src={`${process.env.REACT_APP_MERCHANT_IMAGE}/footerlogo.png`} alt="logo" /></a>
        <Link to="/user/profile" className="mdle pb0">
          <div>
          <Spin spinning={profile.loading}>
            { profileImg ? 
            
          <img src={profileImg} alt="display profile"  style={{width: '100px'}}/>
            :     
          <img src={`${process.env.REACT_APP_IMAGE}/user_profile.png`} alt="display profile" />
          
        }
        </Spin>
        { full_name && 
        <p className="mdle" style={{color: "black"}}>
        <abbr title={full_name}>
          {`${truncateString(full_name)}`}
        </abbr>
        </p>
        }
        </div>
        </Link>

      </div>
      <div className="w-95 aut py1">
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
          <Menu.Item key="1" className="menus">
            <Link to="/user/"  className="aligncent links">
              <Icon icon="fa-solid:th-large" />
              <span className="iconss">Dashboard</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="2" className="menus">
            <Link to="/user/orders"  className="aligncent links">
              <Icon icon="bi:archive-fill" />
              <span className="iconss">Orders</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="3" className="menus">
            <Link to="/user/wallet"  className="aligncent links">
              <Icon icon="ion:wallet" />
              <span className="iconss">Wallet</span>
            </Link>
          </Menu.Item>

          <Menu.Item key="4" className="menus">
            <Link to="/user/notification"  className="aligncent links">
              <Icon icon="clarity:bell-solid-badged" width={20}/>
              <span className="iconss">Notifications</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="5" className="menus">
            <Link to="/user/profile"  className="aligncent links">
              <Icon icon="bx:bxs-user" />
              <span className="iconss">Profile</span>
            </Link>
          </Menu.Item>
        </Menu>
        <div className="py1 mdle">
          <button className="logout" style={{cursor: "pointer"}} onClick={this.logoutUser}>
            <Icon icon="ri:logout-box-line" />
            <span className="iconss"> Logout</span>
          </button>
        </div>
      </div>
    
        </div>
    </Sider>
  );
}
};
const mapStateToProps = ({profile})=>({
    profile
})
export default connect(mapStateToProps, {logout, get_profile})(Siders);
